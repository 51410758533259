import React from 'react';
import { Link } from 'react-router-dom'

const Footer = ({color}) => {
    const style={backgroundColor: color};
    
    return (
        <footer style={style}>
            <div className='footer__desc'>
                <p>The 2019 Chicago Marathon will be held on October 13th. To view more information, click <a href='https://www.chicagomarathon.com/'> here</a>
                </p>
                <p className='illustrations'>Illustrations by <a href='http://freddycarrasco.com/'>Freddy Carrasco</a></p>
            </div>
            <div className='footer__spacer'></div>
            <div className='footer__social'>
                <a href='https://instagram.com/notre'>Instagram</a>
                <a href='https://twitter.com/notre_shop'>Twitter</a>
            </div>
            <div className='footer__copyright'>
                <p>© NOTRE LLC 2019</p>
            </div>
        </footer>
    )
}

export default Footer;