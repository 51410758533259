
import React from 'react';
import EditorialHeader from '../components/EditorialHeader'
import Loading from '../components/Loading'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import ReactVivus from 'react-vivus';
const svg = require('../assets/shoeoutline.svg')

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredRoute: '',
            loading: true
        }
    }

    routes = {
        'chirine': 'https://cdn.shopify.com/s/files/1/0624/0605/files/chirineroute.svg?4238',
        'jigbie': 'https://cdn.shopify.com/s/files/1/0624/0605/files/jigbieroute.svg?4238',
        'crystal': 'https://cdn.shopify.com/s/files/1/0624/0605/files/crystalroute_1.svg?4240',
        'joe': require('../assets/runneroutline.svg'),
        'about': require('../assets/shoeoutline.svg'),
        'register': require('../assets/nikepercentoutline.svg'),
    }

    componentDidMount = () => {
        const seenLoading = sessionStorage.getItem('seenLoading');
        if (!seenLoading) {
            sessionStorage.setItem('seenLoading','true')
        } else {
            this.setState({loading:false});
            return;
        }

        const images = [
            'https://cdn.shopify.com/s/files/1/0624/0605/files/chicagomap.svg?4225',
            'https://cdn.shopify.com/s/files/1/0624/0605/files/Runner1_line.png?4214',
            'https://cdn.shopify.com/s/files/1/0624/0605/files/Runner2_line.png?4214',
            'https://cdn.shopify.com/s/files/1/0624/0605/files/Runner3_line.png?4214',
            'https://cdn.shopify.com/s/files/1/0624/0605/files/Waterbottle_line.png?4214',
            'https://cdn.shopify.com/s/files/1/0624/0605/files/Percent_line.png?4214'
        ];
        const promises = images.map(d => {
            return new Promise((resolve,reject) => {
                let img = new Image();
                img.src = d;
                img.onload = () => resolve('ok');
            })
        })

        setTimeout(() => {
            Promise.all(promises).then(res => {
                this.setState({loading: false})
            })
        },3000)  
    }

    componentDidUpdate = () => {
        if (this.state.hoveredRoute === 'joe') {
            const elem = document.querySelectorAll('#joe')
        }
    }

    onMouseEnter = (id) => {
        window.innerWidth > 768 && this.setState({hoveredRoute: id})
    }

    onMouseLeave = () => {
        this.setState({hoveredRoute:''})
    }

    render() {

        const route = this.state.hoveredRoute.length > 0 && (() => {
            if (!['joe','about','register'].includes(this.state.hoveredRoute)) {
                return (
                <Fade duration={1000} clear>
                    <img className='menu__hover' src={this.routes[this.state.hoveredRoute]} alt='hovered route'/>
                </Fade>
            )} else {
                return (
                    <ReactVivus
                    id={this.state.hoveredRoute}
                    className='menu__hover svg-animate'
                    option={{
                        file: this.routes[this.state.hoveredRoute],
                        type: 'delayed',
                        animTimingFunction: 'EASE_IN',
                        duration: 150
                    }}
                />
                )
            }
            //return <SVG className='menu__hover joe' id='joe' src='https://cdn.shopify.com/s/files/1/0624/0605/files/runneroutline_animated.svg?4251' />
        })()

        if (this.state.loading) {
            return <Loading />
        }

        return (
            <Fade>
            <main className='menu'>
                <EditorialHeader title="FOCUS ON FAST: NEXT% AT NOTRE"/>
                <img className='menu__map' src='https://cdn.shopify.com/s/files/1/0624/0605/files/chicagomap.svg?4225' alt='Chicago Map'/>
                {route}
                <h1 className='title'>FOCUS ON FAST: NEXT% AT NOTRE</h1>
                <div className='menu__links'>
                    <ul>
                        <MenuLink
                            title='Nike Next%'
                            category='ABOUT'
                            link='about'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Percent_line.png?4251'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/percent_fill.png?4251'
                            id='about'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                            className='test'
                        />
                        <MenuLink
                            title='Chirine Njeim'
                            category='ROUTE TOUR'
                            link='interviews/chirine-njeim'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner1_line.png?4214'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner1b_fill.png?4199'
                            id='chirine'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                        <MenuLink
                            title='Jigbie Aguirre'
                            category='ROUTE TOUR'
                            link='interviews/jigbie-aguirre'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner2_line.png?4214'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner2b_fill.png?4199'
                            id='jigbie'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                        <MenuLink
                            title='Crystal Rosales'
                            category='ROUTE TOUR'
                            link='interviews/crystal-rosales'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner3_line.png?4214'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/Runner3b_fill.png?4199'
                            id='crystal'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                        <MenuLink
                            title='Joe Holder'
                            category='INTERVIEW'
                            link='interviews/joe-holder'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Waterbottle_line.png?4214'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/Waterbottle_fill.png?4199'
                            id='joe'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                        <MenuLink
                            title='Joe Holder'
                            category='TUTORIAL'
                            link='interviews/joe-holder-tutorial'
                            iconLine='https://cdn.shopify.com/s/files/1/0624/0605/files/Waterbottle_line.png?4214'
                            icon='https://cdn.shopify.com/s/files/1/0624/0605/files/Waterbottle_fill.png?4199'
                            id='joe'
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ul>
                </div>
            </main>
            </Fade>
        )
    }
}

const MenuLink = ({title,category,link,icon,iconLine,disabled,id,...props}) => {
    const disabledClass = disabled ? 'disabled' : '';
    return (
        <li 
            className={'menu__link ' + disabledClass}
            onMouseEnter={() => props.onMouseEnter(id)}
            onMouseLeave={() => props.onMouseLeave()}
        >
            <Link to={link}>
                <div className="menu__images fill-container">
                    <img src={iconLine} alt="icon line"/>
                    <img className='fill' src={icon} alt="icon"/>
                </div>
                <div>
                    <h5>{category}</h5>
                    {title}
                </div>
            </Link>
        </li>
    )
}

const LinkOut = ({title,category,link,icon,iconLine,disabled,id,onMouseEnter,onMouseLeave}) => { 
    const disabledClass = disabled ? 'disabled' : '';
    
    return (
        <li 
            className={'menu__link ' + disabledClass}
            onMouseEnter={() => onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave()}
        >
            <a href={link}>
                <div className="menu__images fill-container">
                    <img src={iconLine} alt="icon line"/>
                    <img className='fill' src={icon} alt="icon"/>
                </div>
                <div>
                    <h5>{category}</h5>
                    {title}
                </div>
            </a>
        </li>
    )
}
export default Menu;