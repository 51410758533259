import React from 'react';
import { BrowserRouter as Router,Route } from 'react-router-dom';
import Menu from './pages/Menu';
import Editorial from './pages/Editorial';
import About from './pages/About'
import './App.css';

const App = () => {
    return (
        <Router>
            <div className="App">
                <Route exact path='/' component={Menu}/>
                <Route exact path='/about' component={About}/>
                <Route path='/interviews/:page' component={Editorial}/>
            </div>
        </Router>
    )
}
export default App;
