import React from 'react';
import {Link} from 'react-router-dom';

const EditorialHeader = ({title}) => {
    const color = window.location.pathname === '/' ? '#F0498A' : ''
    const style = color.length > 0 ? { color: color} : {}

    return (
        <header className="editorial__header">
            <div className="editorial__header-logo fill-container">
                <Link to='/'>
                    <img src='https://cdn.shopify.com/s/files/1/0624/0605/files/notre_new_line.png?4253' alt='logo line'/>
                    <img className='fill' src='https://cdn.shopify.com/s/files/1/0624/0605/files/notrenew_blackfill.png?4253' alt='logo line'/>
                </Link>
            </div>
            <h1 className="editorial__header-title">{title}</h1>
            <div className="editorial__header-menu" >
                <Link style={style} to='/'>HOME</Link>
            </div>
        </header>
    )
}

export default EditorialHeader;