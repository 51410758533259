import React from 'react';
import Data from './data.js';
import EditorialHeader from '../components/EditorialHeader'
import Footer from '../components/Footer'
import EditorialBlock from '../components/EditorialBlock'

const About = () => {
    /*
    Use page to get correct data for page
    */
   const data = Data['about'].content;
   //d3 gradient interpolator: takes value between 0 and 1
   const gradientInterpolator = Data['about'].gradientInterpolator;
   const elementCount = data.length;

   const gradientValues = [gradientInterpolator(0),gradientInterpolator(elementCount)]
   const gradient = `linear-gradient(180deg, ${gradientValues[0]} 0%, ${gradientValues[1]} 100%)`;
   const style = {background: gradient}

   return (
       <main style={style}>
           <EditorialHeader title={Data['about'].title} />
           <div className="about" >
               <h1 className='title'>{Data['about'].title}</h1>
               {
                   data.map( (d,i) => {
                       return (
                        <div className={d.className}>
                            {d.children}
                        </div>
                       )
                   })
               }
               <Footer color={gradientInterpolator(1)}/>
           </div>
       </main>
   )
} 

export default About;