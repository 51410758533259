import React from 'react';
import Data from './data.js';
import EditorialHeader from '../components/EditorialHeader'
import Footer from '../components/Footer'
import EditorialBlock from '../components/EditorialBlock'

const Editorial = ({match}) => {
    /*
    Use page to get correct data for page
    */
    const page = match.params.page;
    const title = Data[page].title;
    const data = Data[page].content;
    //d3 gradient interpolator: takes value between 0 and 1
    const gradientInterpolator = Data[page].gradientInterpolator;
    const elementCount = data.length;
    const screenWidth = window.innerWidth;

    return (
        <main>
            <EditorialHeader title={title} />
            <div className="editorial" >
                <h1 className='title' style={{backgroundColor: gradientInterpolator(0)}}>{title}</h1>
                {
                    data.map( (d,i) => {
                        const gradientValues = [gradientInterpolator(i / elementCount),gradientInterpolator((i+1) / elementCount)]
                        const gradient = `linear-gradient(180deg, ${gradientValues[0]} 0%, ${gradientValues[1]} 100%)`;
                        const style = {background: gradient}
                        const includeSwipe = (screenWidth < 769 && d.className.includes('-image'));

                        return (
                            <EditorialBlock className={d.className} gradient={style} key={i}>
                                {d.children}
                                { includeSwipe && <div className="swipe">SWIPE →</div>}
                            </EditorialBlock>
                        )
                    })
                }
                <Footer color={gradientInterpolator(1)}/>
            </div>
        </main>
    )
} 
export default Editorial;