import React from 'react';
import {interpolateRgb} from 'd3-interpolate';
import ReactVivus from 'react-vivus';
import Fade from 'react-reveal/Fade';

const screenWidth = window.innerWidth;

//Defaults to high width because we want some mobile elements to still have fade effect
const withFade = (elem,width=10000) => {
    if (width < 769 ) return elem;
        
    return(
        <Fade bottom distance="30px">
            {elem}
        </Fade>
    )
}

let Data = {};
Data['chirine-njeim'] = {
    title: "ROUTE TOUR: CHIRINE NJEIM",
    gradientInterpolator: interpolateRgb('#94CFBE','#DBEAE5'),
    content: [
        {
            className: 'editorial__twos',
            children: [
                <p>
                    In the lead up to this year’s marathon, we asked three local runners to share their favorite routes and a bit about themselves. Chirine Njeim—three-time Olympian, marathoner, Nike athlete, and representative of Second City Track Club—prefers the lakeside scenery.
                    <br/><br/>
                    <i>
                        Chirine runs the Chicago Lakefront Trail, from West Belmont to Navy Pier and back.
                        <br/><br/>
                        Distance: 7.6 miles
                    </i>
                </p>
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-1.jpg?4223'/>,screenWidth)
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are you from Chicago originally? How long have you lived here?
                    <br/><br/>
                    <b>Chirine:</b> A little about me: I’m already a three-time Winter Olympian. I represented Lebanon, my birth country. in the 2002, 2006, and 2010 games as an alpine skier—Downhill, Slalom, Super-G, GS and Super-Combined events. I left my home at 12 years of age to train in France, and then again at 14 to attend high school in Utah at an elite ski academy. I went onto college studies at the University of Utah and then moved to Chicago in 2010. I currently live in Lakeview with my husband and our Golden Retriever.
                </p>,
                <p>
                    <b>Notre:</b> Tell us about your route. Why did you choose it? How often do you run it? Does it have any special significance to you?
                    <br/><br/>
                    <b>Chirine:</b> Living in Lakeview, it’s very convenient to make it to the path, where there are no stop lights, no cars, and a breathtaking view of the city from afar. I usually start at Belmont and run south towards Ohio Beach. I do this run every day. The nice thing about this route is there are bathrooms and water fountains available along the way—important on hot days. Along the way, you see and hear the sailboats, trees, and run along the waterfront and watch the waves crash. The most beautiful part of this route is approaching North Avenue when you see the city. It’s a view that never gets old. Every day the view is different. Rain, snow, fog—it is just unbelievably beautiful.                 
                </p>            
            ]
        },
        {
            className: 'editorial__full',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-2.jpg?4223' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-3-square.jpeg?4223' />,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-4-square.jpg?4223' />,screenWidth)          
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> How long have you been running for?
                    <br/><br/>
                    <b>Chirine:</b> I didn’t run in high school or college. I started in 2010, when I moved to Chicago and realized there were no mountains. I had to pick another hobby, so I picked running. My husband and I decided to run the city and registered for the Chicago Marathon in 2012. The whole idea was to scratch “marathon” off the list of things to do, but I was hooked, and the rest is history.
                </p>,
                <p>
                    <b>Notre:</b> Beyond physical fitness, what role does running play in your life?
                    <br/><br/>
                    <b>Chirine:</b> Being from Lebanon, running means a lot to me. I run for peace and for my country. I want to send a message to the younger generation that if there is a will, there is a way. It doesn’t matter where you’re from. If you have a goal, write it down, and give it your best to reach it.
                    <br/>
                    Every year, I go to Lebanon and run the Beirut marathon. It is a marathon that gives me so much joy! Seeing everyone running for the same goal and purpose, and having local supporters cheering along the way is all I need to keep me motivated to represent this beautiful country. 
                </p>
            ]
        },
        {
            className: 'editorial__portrait-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-5.jpg?4223'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-6.jpg?4223'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-7.jpg?4223'/>,screenWidth)
            ]
        },
        {
            className: 'editorial__quote',
            children: [
                <p>
                    “I want to send a message to the younger generation that if there is a will, there is a way. It doesn’t matter where you’re from. If you have a goal, write it down, and give it your best to reach it.”
                </p>
            ]
        },
        {
            className: 'editorial__full',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-8.jpg?4223' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are there any particular running-related goals you're working towards right now?
                    <br/><br/>
                    <b>Chirine:</b> Right now, I train with Second City Track Club, local running team and they are helping me be my best! I would love to represent Lebanon in Tokyo again, but my main focus for Chicago is to run the best I can and will see what happens next.
                </p>
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/chirene-9.jpg?4223'/>,screenWidth)
            ]
        },
    ]
}

Data['jigbie-aguirre'] = {
    title: "ROUTE TOUR: JIGBIE AGUIRRE",
    gradientInterpolator: interpolateRgb('#F4C67F','#EFE7DC'),
    content: [
        {
            className: 'editorial__twos',
            children: [
                <p>
                    In the lead up to this year’s marathon, we asked three local runners to share their favorite routes and a bit about themselves. Jigbie Aguirre of GO! City Runners’ starts off in Lincoln Square.
                    <br/><br/>
                    <i>
                        Start at North Lincoln and West Leland<br/>
                        South on North Lincoln to West Belmont<br/>
                        East on West Belmont to North Sheffield<br/>
                        North on North Sheffield to North Clark<br/>
                        Northwest on North Clark to West Leland<br/>
                        West on West Leland to North Lincoln<br/>
                        <br/>
                        Distance: 5.8 miles
                    </i>
                </p>,
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-1.jpg?4226'/>,screenWidth)
            ]
        },
        {
            className:'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are you from Chicago originally? How long have you lived here?
                    <br/><br/>
                    <b>Jigbie:</b> I am born and bred in Chicago and live in the Rogers Park neighborhood.
                    <br/><br/>
                    <b>Notre:</b> Tell us about your route. Why did you choose it? How often do you run it? Does it have any special significance to you?
                </p>,
                <p>
                    <b>Jigbie:</b> I chose this route because I love running through the city. As much as I enjoy the lakefront path, when you run through the city and the different neighborhoods, it's so much more dynamic. You could come across new shop, a new art piece, or find even small detours that take you down a cool block. I probably run this route with the crew at least every month or so when it's not marathon training season. While the actual route doesn't have any particular significance to me, where it starts, Lincoln Square, does. Before imagining GO! City Runners, I had signed on to be a pacer for one of the larger training programs out there, and that's the time when I feel I unlocked a passion to share and teach and learn with others. Those training runs would start from Lincoln Square, so I always enjoy getting things going from there.
                </p>
            ]
        },
        {
            className: 'editorial__portrait-image', 
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-11.jpg?4234'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-6.jpg?4234'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-7.jpg?4234'/>,screenWidth)
            ]
        },
        {
            className: 'editorial__full',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-4.jpg?4234' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos', 
            children: [
                <p>
                    <b>Notre:</b> How long have you been running for?
                    <br/><br/>
                    <b>Jigbie:</b> I ran some track in high school, but generally sprint distances. I discovered long distance running in 2011 and don't plan to ever stop.
                    <br/><br/>
                    <b>Notre:</b> Beyond physical fitness, what role does running play in your life?
                </p>,
                <p>
                    <b>Jigbie:</b> Running plays a few roles in my life. Of course there's the performance aspect, but because I want to be in tune with my physical, I have become more conscious about what I eat and how I treat my body. Running also serves as my time to meditate and self-reflect. Whether it's a long run or speed workout, there's nothing in the week I focus on without interruptions longer than when I'm running. When you're out there, you're not checking email, not on social media, et cetera. Running has allowed me to continue to meet so many different people and discover inspiration and like-minds from all over the world. Even here in Chicago, nothing beats being out on the road and seeing other friends who are out there putting in work.
                </p>
            ]
        },
        {
            className: 'editorial__twos-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-2.jpg?4236' />,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-3.jpg?4236' />,screenWidth)          
            ]
        },
        {
            className: 'editorial__quote',
            children: [
                <p>
                    Whether it's a long run or speed workout, there's nothing in the week I focus on without interruptions longer than when I'm running. When you're out there, you're not checking email, not on social media, et cetera.
                </p>
            ]
        },
        {
            className: 'editorial__full',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-8.jpg?4236' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are there any particular running-related goals you're working towards right now?
                    <br/><br/>
                    <b>Jigbie:</b> I'm realistic and I know I'm not going to be winning any races or age groups, but one goal is to always to improve and perform to the best of my ability. My other goal is to get back to travelling for different races.
                </p>,
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jigbie-9.jpg?4236'/>,screenWidth)
            ]
        }
    ]
}

Data['crystal-rosales'] = {
    title: "ROUTE TOUR: CRYSTAL ROSALES",
    gradientInterpolator: interpolateRgb('#E4BAB5','#ECDBCC'),
    content: [
        {
            className:'editorial__twos',
            children: [
                <p>
                    In the lead up to this year’s marathon, we asked three local runners to share their favorite routes and a bit about themselves. Crystal Rosales is relatively new to the sport, having only picked it up in earnest in late 2018. She runs with 3run2, and is training for her first marathon in spring 2020. Crystal’s route crosses a few southside neighborhoods. 
                    <br/><br/>
                    <i>
                        Start at South Halsted and West Maxwell<br/>
                        South on South Halsted to West Cermak<br/>
                        East on West Cermak to South State<br/>
                        North on South State to West Roosevelt<br/>
                        West on West Roosevelt to South Halsted<br/>
                        South on South Halsted to West Maxwell<br/><br/>
                        Distance: 4 miles
                    </i>
                </p>,
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-1.jpg?4249' />,screenWidth)
            ]
        },
        {
            className:'editorial__full',
            children:[
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-2.jpg?4249' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are you from Chicago originally? How long have you lived here?
                    <br/><br/>
                    <b>Crystal:</b> I’m originally from Milwaukee, and actually just celebrated that 10-year Chicago anniversary last month! I currently live in River West. It’s a quiet little pocket between Goose Island and Noble Square. 
                </p>,
                <p>
                    <b>Notre:</b> Tell us about your route. Why did you choose it? How often do you run it? Does it have any special significance to you?
                    <br/><br/>
                    <b>Crystal:</b> I was introduced to this route when I joined the 3run2 running crew. I try to run this four or five mile route every Tuesday. Chicago runners are very spoiled with the lakefront path and skyline backdrop, but I love this particular route because it reminds me of why I moved to Chicago, which was the vibrant and unique neighborhoods. On Tuesdays, I’m able to University Village, Pilsen, Chinatown, and South Loop in just one run, and maybe sneak in a bubble tea afterwards.
                </p>            
            ]
        },
        {
            className: 'editorial__portrait-image', 
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-3.jpg?4249'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-4.jpg?4249'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-5.jpg?4249'/>,screenWidth)
            ]
        },
        {
            className:'editorial__full',
            children:[
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-6.jpg?4249' />,screenWidth)
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> How long have you been running for? 
                    <br/><br/>
                    <b>Crystal:</b> I’ve done a handful of 5k races and the Humboldt Mile for charity in the past, but I didn’t start taking running seriously until this March, right before the 2019 Shamrock Shuffle 8k. I actually really enjoyed that race, and it inspired me to sign up for my first half marathon. I was a total naïve beginner that hadn’t ran more than 5 miles before signing up. I knew training for a half marathon was going to be challenging, but I made it my goal. It felt so good to cross that 13.1 finish line. 
                </p>,
                <p>
                    <b>Notre:</b> Beyond physical fitness, what role does running play in your life? 
                    <br/><br/>
                    <b>Crystal:</b> I quit drinking in December 2018, which required a major lifestyle change. I found that running gave me the motivation and discipline I needed and also became my form of therapy. My runs allow me to decompress, reflect and let out any frustrations about regular life stuff and my sobriety. It’s definitely a much needed outlet. 
                </p>            
            ]
        },
        {
            className: 'editorial__twos-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-7.jpg?4249' />,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-8.jpg?4249' />,screenWidth)          
            ]
        },
        {
            className: 'editorial__quote',
            children: [
                <p>
                    I found that running gave me the motivation and discipline I needed and also became my form of therapy.
                </p>
            ]
        },
        {
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Notre:</b> Are there any particular running-related goals you're working towards right now?
                    <br/><br/>
                    <b>Crystal:</b> I finally had the courage to sign up for a marathon, so I’ll be running the Milwaukee Marathon in April 2020! I plan to start my training in less than ideal conditions—early December—but I’m really looking forward to pushing myself. In the meantime, I’m trying to stay consistent and hit at least 15 to 20 miles a week. 
                </p>,
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/crystal-9.jpg?4249'/>,screenWidth)
            ]
        }
    ]
}

Data['joe-holder'] = {
    title: "JOE HOLDER WANTS YOU TO FIND YOUR FREEDOM",
    gradientInterpolator: interpolateRgb('#B5C7D1','#C8CACC'),
    content: [
        {
            className: 'editorial__twos joe joe-portrait',
            children: [
                <p>Joe Holder is a busy man. He’s got his training practice, which sees him working with top-of-their-field clients from supermodels Naomi Campbell and Romee Strijd to elite athletes like Kelley O’Hara of the US Women’s National Soccer Team. As a Nike Master Trainer, he bridges the gap between lifestyle and performance—leading workouts in the Italian Alps as part of the launch of Matthew Williams’ latest Nike collab, for instance. He’s also <i>GQ</i>’s new in-house wellness expert. And then there are his own projects, like Plant Based Gang, and System of Service, and his own personal fitness goals—like running marathons, which he’ll do in Chicago this coming weekend.
                    <br/><br/>
                    Suffice to say that Joe doesn’t get a ton of downtime. That’s probably why he so acutely understands its importance, and that's one piece of the puzzle that makes his approach to wellness so compelling: Joe advocates for taking time and doing less as much as he does rising and grinding. His vision of wellness is truly holistic, grounded in ideas of balance and empathy, folding in eclectic inputs, and treating personal improvement as a slow, steady process rather than a sprint. As he recently wrote in the caption of an Instagram post: “Micro shifts make a major difference.” We couldn’t think of anyone who better embodies the Next% mindset. 
                    <br/><br/>
                    Sydney Allen Ash met up with Joe in New York City to discuss approaches to optimization, the importance of vulnerability, his preparation for the Chicago Marathon, and more. 
                </p>,
                ,withFade(<img className='joe-portrait' src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-2.jpg?4259' />)
            ]
        }, 
        {
            className: 'editorial__twos joe',
            children: [
                <p>
                    <b>Sydney:</b> The word optimization frequently comes up in interviews that you do. Making the most of your time, being really efficient. How you maintain such a deep spiritual and philosophical outlook on health, while also focusing on optimization? Those two things feel like they're at opposite poles.
                    <br/><br/>
                    <b>Joe:</b> It depends on how you look at optimization. For me, optimization starts within the philosophical or spiritual framework of how I view work, how I view existence, how I view life. Wellness, to me, is a revolutionary act that allows you to remove yourself from this fluff. You should use optimization to create a workflow where instead of viewing yourself as a product, you view yourself as a piece of art. If you place optimization within that framework of self-revolution it becomes a little different.
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-11.jpg?4271' />)
            ]
        }, 
        {
            className:'editorial__quote',
            children: [
                <p>
                    You should use optimization to create a workflow where instead of viewing yourself as a product, you view yourself as a piece of art.
                </p>
            ]
        },
        {
            className:'editorial__full',
            children:[
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-6.jpg?4272' />,screenWidth)
            ]
        },
        {
            className:'editorial__twos joe',
            children: [
                <p>
                    <b>Sydney:</b> It becomes way more humane. Where does optimization of training lead into a flow state?
                    <br/><br/>
                    <b>Joe:</b> That's all training is, in my opinion—it’s a creation of a flow state. So, there's a sweet spot within training, within life, within running: it’s consistent action of learning where you're getting feedback, whether you're getting it from your body or your coach, and then applying it to the next session. That session is not so far outside of your comfort zone that it's destructive, and it's not so easy that it's not really challenging at all.
                    <br/><br/>
                    For instance, if I have a game, my game should always be a little bit easier, in theory, than the practices. That's why you have training camps—that's why you go through these tough moments. I think training, both within the physical and mental realm, helps you in dealing with the hardships in life as they come.
                    <br/><br/>
                    In that sense, everything's training. It's those small moments in the morning where I'm like, "Okay, hold up. I'm not just going to hop on my phone ‘til I sit and breathe for five to 10 minutes.” Can I optimize these 10 minutes so I get into contact with me? That's how you optimize your training.
                    <br/><br/>
                    <b>Sydney:</b> Is it uncomfortable for you if people equate your work with optimization in a more robotic sense than what we just talked about?
                </p>,
                <p>
                    <b>Joe:</b> I don't know how people equate it. I hope people understand that you're not taking care of yourself so you can work. You take care of yourself so you can serve, and there's a distinct difference between those two things.
                    <br/><br/>
                    <b>Sydney:</b> Do you want to explain the difference?
                    <br/><br/>
                    <b>Joe:</b> You serve your community. You serve your family. Of course you serve yourself so that you can feel good. But you don't do it to work. You don't do it just to be a cog. I get it, people still have to work, but I want people to free themselves. I want people to run because that's an act of freedom. I want people to reclaim their physical wellness, their mental wellness, their emotional wellness, their spiritual wellness. Take care of yourself so we can figure out a way to take care of each other in this world.
                    <br/><br/>
                    <b>Sydney:</b> Something that I read mentioned that when you start training new clients you try to build a lot of trust, because there's vulnerability in wanting to get better— which I believe. Could you talk a little bit more about the vulnerability involved in training? It seems to be misinterpreted by most people that vulnerability is weakness and that it’s counterintuitive to the aggression needed to get better physically.
                </p>
            ]
        }, {
            className:'editorial__portrait-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-12.jpg?4272'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-9.jpg?4265'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-3.jpg?4239'/>,screenWidth)
            ]
        }, {
            className:'editorial__twos joe',
            children: [
                <p>
                    <b>Joe:</b> You can't even tap into that special aggression unless you've been fully vulnerable. You've got to be fully vulnerable to something that you believe in. When a client comes to me, it's sometimes very difficult because they think something's wrong. I don't want you to think there's something wrong. I want you to take pride in the fact that you want to do something better, and, of course, be better for yourself. 
                    <br/><br/>
                    Being vulnerable is the greatest act of strength, because you’ve got to look at yourself and you’ve got to say, "I want to work on this. I'm going to have somebody critique me, encourage me and help me move forward." But you’ve got to let it all go. 
                    <br/><br/>
                    <b>Sydney:</b> Listening to you talk, I’m realizing that you're trying to create behavior change rooted in empathy, rooted in understanding, vulnerability, kindness, and self-awareness. How far along in your career did you start to more consciously employ that perspective in your work?
                    <br/><br/>
                    <b>Joe:</b> I don't think it's ever not been a part of me. I’m one of seven kids. My dad's an integrated holistic MD. My mom's an immigrant from Trinidad. Esoteric household, religion-wise. And incorporated physicality has always been a component of my upbringing.
                </p>,
                <p>
                    What I began to see is that if you don't have a physical understanding of yourself then there's going to be a disconnect. If you have dominion over your physical health in a certain way, you should use that to galvanize you in other areas. It's an onboarding ramp. In college, I was humbled by a lot of experiences, especially through injury, and I realized a very toxic, alpha dog mindset just didn't make sense. But I also began to see people that had never played sports before just had a skewed view of reality that didn't make sense. They didn't really understand teamwork, sacrifice, or pain. When you've dealt with traumatic situations and really experienced pain, that totally changes who you are as a person. Then, I argue, you either become exceedingly selfish, or it's impossible for you to be selfish at all. And for me, it became a situation where I realize it's now impossible for me to be selfish. I started to realize that people could have an awakening and understand greater wellness if they were guided along through some core principles, first starting with their physicality.
                    <br/><br/>
                    At the end of the day if I can be a light in the world, and continue my mom and dad's legacy, that’s what’s important. That's why I like the Ocho System: One Can Help Others, Others Can Help One. I think you can create an infinite feedback loop if gratitude is grounded first in the physical experience. 
                </p>
                
            ]
        },
        {
            className: 'editorial__twos joe',
            children: [
                <p>
                    <b>Sydney:</b> Switching gears for a second—you have a marathon in 14 days.
                    <br/><br/>
                    <b>Joe:</b> Fuck. You're right.
                    <br/><br/>
                    <b>Sydney:</b> Most people would be in taper mode right now. I'm not going to assume that you're following best practices. What are you doing right now?
                    <br/><br/>
                    <b>Joe:</b> Best practices are different for every person, I guess. I don't train in a typical marathon manner, so I wouldn't expect the lead up to the marathon to be the same thing. My body will be alright, I just need to figure out what's up with my mind. So, I'm just going to take the next two weeks to work on that. I'm not tapering per se, I'm probably going to actually start running a little bit more to get my body used to it. I'm doing a marathon in December, also, so I'm looking at Chicago as a jump start for what I want to do then. 
                    <br/><br/>
                    <b>Sydney:</b> A lot of what I read you talk about with training is focused on continued growth for a long period of time. I'm curious if you change your approach when it comes to training for a discrete goal, like a marathon.
                </p>,
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-5.jpg?4260' />,screenWidth)
            ]
        },
        {
            className:'editorial__full',
            children:[
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-7.jpg?4262' />,screenWidth)
            ]
        },{
            className:'editorial__twos joe',
            children: [
                <p>
                    <b>Joe:</b> There are different tactics that I put in through different periods of the training. I think that's the biggest thing that people mess up on, because we're hit with so much information but nobody's actually telling us how to implement it. With my training I want to go from theory to practicality to individuality. If something sounds good, that’s all good and fine, but what's actually the proper dose, when do I need to do it, how does it affect me, when is the proper timing? Optimization is nothing more than being able to go to a class, learn, and then be able to apply it. I think that same thought process could be applied to life.
                    <br/><br/>
                    <b>Sydney:</b> There's a couple myths that I would like for you to dispel. The first one is this idea that in order to be creative—and I mean creative in the broadest sense of the word—you need to be unhealthy. What are your thoughts on the romanticization of the unhealthy creative?
                    <br/><br/>
                    <b>Joe:</b> I think we all deal with unhealthy habits that we don't like to talk about, because it doesn't paint us in the best light. That's why it's important to be healthy, because when you have healthy habits, you can deal with the unhealthy aspects of what it means to be great. When you want to push yourself to certain limits, there are inherently unhealthy aspects that lead to higher performance. When I want to perform it's not always healthy. Sometimes I have to pull multiple nights where I get little sleep, but it'll probably lead to a higher performance. You have to look at those consistent conflicts.
                    </p>,
                <p>
                    The question is, can you balance those unhealthy aspects of your life with other healthy habits that allow you to create a wholesome person? Life is inherently unhealthy. Life is killing you. But if you have healthy habits then your body regens and hopefully creates something that allows it to put up with the stress of life. 
                    <br/><br/>
                    <b>Sydney:</b> The other myth is the positive thinking myth. You don't use the wellness rhetoric that a lot of people are using that's really fluffy and meaningless—"Oh, just live your best life.” 
                    <br/><br/>
                    <b>Joe:</b> Exorbitant positive thinking is not the way that most people have solved issues. I'm more of a fan of being pragmatic. You hope for the best, but you work for what's real. But a lot of people just hope for the best without working and that decreases your motivation because your brain thinks you've gotten done what it is that you're constantly yearning to do. You have to envision things going positively but also envision the roadblocks that may be ahead—then you can mentally prepare yourself for how you are going to respond to that.
                    </p>
            ]
        },{
            className:'editorial__portrait-image',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-8.jpg?4265'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-4.jpg?4259'/>,screenWidth),
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-10.jpg?4265'/>,screenWidth)
            ]
        },
        {
            className: 'editorial__twos joe',
            children: [
                <p>
                    <b>Sydney:</b> Mental contrasting.
                    <br/><br/>
                    <b>Joe:</b> Yeah, mental contrasting. In college every time before a game I would sit there and meditate, envisioning myself running specific routes and, of course, catching the ball. But then sometimes I would envision me dropping a pass, me missing a block. Then you have to ask yourself, "How am I going to respond to that? What did I actually do wrong?" Nowadays, especially because a lot of people haven't played a sport, they get hit with something bad and then they just give all that shit up, instead of saying, "How can I be resilient? How can I bounce back?"
                    <br/><br/>
                    <b>Sydney:</b> I agree with you. On most days I find deep satisfaction by personal growth gained through tactics like that. But, some days, I'm filled with existential dread and I can’t help but think adulthood is characterized by so much self-restraint. Similarly, something you mentioned in an interview about being a vegetarian is that it is a chosen asceticism, a controlled renunciation. So, my question for you is, with all this focus on discipline and self-improvement, when do you feel free?
                </p>,
                <p>
                    <b>Joe:</b> I feel free when I'm running down the street without pain. When I'm sitting on a plane in the air and realize that I'm hustling health and people believe in me, and I've turned a wellness dream into a livelihood. I feel free when I can go to sleep at the end of the day and know the work that I've put in is good work.
                    <br/><br/>
                    It's a difficult question because this whole thing is nothing more than an experiment. I never got into this because I was like, "This is what I want to be". No—I got into this because I'm just trying to heal my body and heal my mind.
                    <br/><br/>
                    I think in terms of the Next% mentality, that next great step in human evolution and fitness is really going to be figuring out how we optimize the mind to overcome the stress that the body experiences. I don't think we take the mind into consideration enough with our fitness strategies. So, that's something of interest to me—upgrading the human iOS. Through the lens of running or training or whatever, how do we take the next great step that allows us to function at a higher level?
                    <br/><br/>
                    <i>Interview: Sydney Allen Ash
                    <br/>
                    Photographs: Caloy DeGuzman  </i>  
                </p>
            ]
        }
        ,{
            className:'editorial__full',
            children: [
                withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-1.jpg?4256' />,screenWidth) 
            ]
        }
    ]
}

Data['joe-holder-tutorial'] = {
    title: 'Preparing for the Next Percent',
    gradientInterpolator: interpolateRgb('#c860a2','#DBEAE5'),
    content: [
        {
            className: 'editorial__twos joe-portrait',
            children: [
                <p>
                    Joe Holder has been prepping for the Chicago Marathon, so we asked him to share some of his strategies with us. Below, he walks us through his pre-run warm-up routine. He also wanted to share a couple other tactics he relies on. One of them is heat adaptation. “Utilizing heat post running during my base building stage helps me find improvement in my mitochondria or overall fitness. Much of this is conjecture but some of it seems to be backed by science as well—’mild heat stress.’ I either take a hot bath or 30-minute infrared sauna post runs.” Nutritional strategies are important, too. “Proper diet can help improve performance outcomes,” Joe explains. “Eating a diet with naturally occurring nitrates—my choice is arugula and beet juice—or an overall plant-based or Mediterranean diet can result in better running outcomes overall.”
                    Finally, Joe has a few other complementary products he leans on during prep. "Basically, once you do things to take care of your body, can you take it to the next level and utilize products—including sneakers—to do the same? A few of mine include PowerDot, Hyperice, and Whoop."
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/joeholder-13.jpg?4325' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Breathing Practice</b>
                    <br/>
                    <i>Good for: Nervous System</i>
                    <br/>
                    <i>Amount: 1x8 breaths</i>
                    <br/><br/>
                    How-to: life is akin to a series of rituals and breathing helps get you grounded. I like to incorporate a breathing routine that allows me to get calm before I start to rev up for the run. Laying on your back, find a comfortable position. If needed, place one hand on your stomach and the other on your chest. Take a deep breath in, filling up your diaphragm first before your chest fills up later. Inhale for a count of 2-3 seconds, hold for a count of 3-4, exhale for a count of 4-5. Pause briefly and then repeat. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-1.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Big Toe Floss </b>
                    <br/>
                    <i>Good for: Feet </i>
                    <br/>
                    <i>Amount: 1-2 minutes each foot</i>
                    <br/><br/>
                    How-to: the big toe is responsible for a large percentage of the load of human gait. Lack of mobility in this area can lead to a variety of issues up the chain so take the time to “floss” your foot, especially your big toe. In a seated position, preferably after you’ve rolled your foot out with a lacrosse ball, grab your big toe to create space between the rest of your foot. Flex your toe and move it up and down, side to side using your hand to create a greater range of motion as you go. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-2.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Banded Ankle Drills</b>
                    <br/>
                    <i>Good for: Ankle, Foot</i>
                    <br/>
                    <i>Amount: 1x10 each way on foot</i>
                    <br/><br/>
                    How-to: place your foot within the band. Press out, side to side, and then back towards you. Do 10 reps each way on each ankle. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-3.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Lying Hamstring Floss</b>
                    <br/>
                    <i>Good For: Hamstrings</i>
                    <br/>
                    <i>Amount: 1x8 each leg</i>
                    <br/><br/>
                    How-to: this is a different way to get some “air” into the back of those hamstrings. Laying on your back, bend your left leg slightly and bring your right leg towards your chest. Hook underneath your right leg with both hands. Twist your ankle to the right, this should allow your hamstring to “floss.” Exhale as you straighten your leg. Twist your ankle in towards your midline. Bend leg to start and repeat. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-4.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>T-Spine Sweep</b>
                    <br/>
                    <i>Good For: Mid-back</i>
                    <br/>
                    <i>Amount: 1x8 each way</i>
                    <br/><br/>
                    How-to: laying on your right side with knees bent at 90 degrees, place your right hand on top of your legs. Extend your left hand straight at eye level. Sweep your hand above you as you try to get your shoulders to lay flat. Return to start and repeat. Focus on moving from your upper back and not lower. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-5.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Lying Dynamic Leg Swings</b>
                    <br/>
                    <i>Good For: Hamstrings</i>
                    <br/>
                    <i>Amount: 1x10 each leg  </i>
                    <br/><br/>
                    How-to: laying on your back, bend your left leg and straighten your right. Perform 10 dynamic leg swings on your right leg, making sure not to arch your back.
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-6.gif?4324'/>)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Bird-Dogs</b>
                    <br/>
                    <i>Good For: Back, Hips, Glutes</i>
                    <br/>
                    <i>Amount: 1x10 each side</i>
                    <br/><br/>
                    How-to: get on all fours with your hands underneath your shoulders and your knees underneath your hips—this is your starting position. Bring your right elbow to your left knee and then extend out straight. Repeat on the same side extending 45 degrees this time. Alternate forward and then 45 degrees until you complete 10 reps on each side. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-8.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>“Spiderman” Flow </b>
                    <br/>
                    <i>Good for: Whole body</i>
                    <br/>
                    <i>Amount: 1x8 reps</i>
                    <br/><br/>
                    How-to: begin in a plank position and lower yourself to the ground. Extend up when you reach the bottom slightly through your back. Be controlled here—don’t force! Push back up to start and then step your right foot to the outside of your right hand. Rotate up with your right hand towards the sky. Return hand back down and then push back into a side lunge towards your left leg. Step back into plank position and then repeat. 
                </p>,
                ,,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-9.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Inchworm to Sweeps</b>
                    <br/>
                    <i>Good for: Spine, Back, Hamstrings</i>
                    <br/>
                    <i>Amount: 1 set of 10 </i>
                    <br/><br/>
                    How-to: walk your body out into a plank by crawling forward with your hands. Walk your feet up towards your hands until you get to a forward fold as comfortable as possible. Come up with a slight bend towards your right side in a swooping motion and then bend back down through your left. Repeat, alternating sides you swoop as you go. 
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-10.gif?4324' />)
            ]
        },{
            className: 'editorial__twos',
            children: [
                <p>
                    <b>Side-to-side Dynamic Leg Swings</b>
                    <br/>
                    <i>Good for: Legs</i>
                    <br/>
                    <i>Amount: 1x15 each leg</i>
                    <br/><br/>
                    How-to: facing a wall while using both hands for support, allow your leg to dynamically swing side to side. Try to raise up on the grounded leg’s toe as you swing side to side.
                </p>,
                ,withFade(<img src='https://cdn.shopify.com/s/files/1/0624/0605/files/jh-gif-11.gif?4324' />)
            ]
        },

    ]
}

Data['about'] = {
    title: 'FOCUS ON FAST: NEXT% AT NOTRE',
    gradientInterpolator: interpolateRgb('#E5C3CB','#F4E3E7'),
    content: [
        {
            className: 'editorial__twos about',
            children: [
                <ReactVivus
                    id='shoe'
                    className='about-svg'
                    option={{
                        file: require('../assets/shoeoutline.svg'),
                        type: 'delayed',
                        animTimingFunction: 'EASE_IN',
                        duration: 150
                    }}
                />,
                <p>Nike’s ZoomX Vaporfly Next% is Nike’s fastest, lightest running shoe ever. Its name is derived from the fact that it objectively improves running economy, making runners wearing it at least 4% more energy efficient and giving that extra edge to competitors for whom every millisecond is of crucial importance. </p>
            ]
        },
        {
            className: 'editorial__twos about',
            children: [
                <ReactVivus
                    id='percent'
                    className='about-svg'
                    start='manual'
                    option={{
                        file: require('../assets/nikepercentoutline.svg'),
                        type: 'delayed',
                        animTimingFunction: 'EASE_IN',
                        duration: 150
                    }}
                />,
                <p>And though the pursuit of marginal gains is essential to elite athletes, it’s equally applicable to the committed amateur simply competing with herself. The Next% mentality can even extend beyond athletics into almost any vocation or hobby.   </p>
            ]
        },
        {
            className: 'editorial__twos about',
            children: [
                <ReactVivus
                    id='runner'
                    className='about-svg'
                    start='manual'
                    option={{
                        file: require('../assets/runneroutline.svg'),
                        type: 'delayed',
                        animTimingFunction: 'EASE_IN',
                        duration: 150
                    }}
                />,
                <p>In the lead-up to the 2019 Chicago Marathon, we’re celebrating the Next% and the pursuit of performance both online and IRL—featuring Nike Master Trainer Joe Holder, Nike footwear develop Chris Cook, Nike+ Run Club coach Steve Finley, and a selection of local runners and international artists.</p>
            ]
        },
    ]
}

export default Data;