import React from 'react';
import ReactVivus from 'react-vivus';
import { Link } from 'react-router-dom'

const Loading = () => {
    return (
        <main className='loading'>
            <div className="fill-container">
                <Link to='/'>
                    <img src='https://cdn.shopify.com/s/files/1/0624/0605/files/Notre__logo_500px_line.png?4209' alt='logo line'/>
                    <img className='fill' src='https://cdn.shopify.com/s/files/1/0624/0605/files/Notre__logo_500px_black.png?4199' alt='logo line'/>
                </Link>
            </div>
            <ReactVivus
                id='shoe'
                className='loading__img'
                option={{
                    file: require('../assets/shoeoutline.svg'),
                    type: 'delayed',
                    animTimingFunction: 'EASE_IN',
                    duration: 150,
                    start: 'autostart'
                }}
            />
        </main>
    )
}

export default Loading