import React from 'react';

const EditorialBlock = ({gradient,className,children}) => {
    return(
        <div style={gradient} className={className}>
            {children}
        </div>
    )
}

export default EditorialBlock;